@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap);
:root {
  --dark: #333;
  --primary: #3f43b6 !important;
}

body {
  font-family: "Inter", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

.overflow-hidden {
  overflow: hidden !important;
}

.damage-item {
  text-transform: capitalize;
}

.btn-primary {
  background-color: #3f43b6 !important;
  background-color: var(--primary) !important;
  border: 1px solid white !important;
  color: white !important;
}

.bg-primary {
  color: white !important;
  background-color: #3f43b6 !important;
  background-color: var(--primary) !important;
}

.container-x {
  overflow-x: scroll;
}

.user-icon {
  color: #3f43b6;
  color: var(--primary);
}

.border-1 {
  border: 1px solid #000;
}

.bg-dark-udem {
  background-color: #302c26;
}

.tab {
  padding: 6px;
  cursor: pointer;
}

.br {
  border-radius: 4px;
}

.br-5 {
  border-radius: 5px;
}

.br-10 {
  border-radius: 10px;
}

.selected {
  color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #3f43b6;
  background-color: var(--primary);
}
.login-img {
  object-fit: cover;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #3f43b6;
  background-color: var(--primary);
  color: #000;
}

.vh-50 {
  height: 50vh;
}

.bold {
  font-weight: 700;
}

.form-card {
  max-width: 500px;
  margin: auto;
}

.narrow {
  max-width: 800px;
}

#test-container {
  height: 100vh;
  margin-left: -15px;
  margin-right: -15px;
  text-align: center;
  padding-top: 20%;
}

.hidden {
  display: none;
}

.vh-100 {
  height: 100vh;
}

.sidebar {
  width: 160px;
  padding: 0px;
}

.content {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  padding-top: 8vh;
  padding-bottom: 2rem;
}

.main-panel {
  min-height: 100vh;
  padding: 12px;
  margin-left: 64px;
}

.side-menu {
  left: 0px;
  width: 64px;
  height: 100vh;
  position: fixed;
  padding-top: 5vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 64px;
}

.side-menu .nav-item {
  padding: 6px;
  margin: auto;
  display: block;
  font-size: 20px;
  text-align: center;
}

.side-menu .nav-item:hover {
  background-color: var(--secondary);
}

.side-menu-list {
  display: block;
  list-style-type: none;
  padding: 0px;
}

.half-image {
  height: 100vh;
  width: 100%;
  max-width: 100%;
  object-fit: cover;
}

.usuario-row:hover {
  background-color: var(--light);
  cursor: pointer;
}

.card {
  border-radius: 5px;
}

.card-img {
  height: 250px;
  object-fit: cover;
}

.btn-close {
  position: absolute;
  top: 5px;
  right: 0;
}

.text-underline {
  text-decoration: underline;
}

.torre-row {
  height: 400px;
  margin-top: 100px;
}

.stick {
  width: 20px;
  height: 250px;
  margin: auto;
  display: block;
  background: linear-gradient(to right, #d7b889, #b27315, #966f33);
}

.base {
  height: 25px;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.3), transparent),
    linear-gradient(
      #b27315,
      #966f33 13%,
      #faddb0 14.5%,
      #faddb0 27.5%,
      #966f33 29%,
      #966f33 42%,
      #faddb0 43.5%,
      #d7b889 56.5%,
      #966f33 58%,
      #966f33 71%,
      #d7b889 72.5%,
      #d7b889 85.5%,
      #966f33 87%,
      #b27315
    );
}

.rojo {
  background: linear-gradient(to right, #f2a097, #ff3a24, #e74c3c);
}

.negro {
  background: linear-gradient(to right, #666, #000, #333);
}

.amarillo {
  background: linear-gradient(to right, #f0d775, #fc0, #e6bd19);
}

.verde {
  background: linear-gradient(to right, #8ac4ea, #0f9fff, #3498db);
}

.naranja {
  background: linear-gradient(to right, #eea668, #ed7e1d, #e67e22);
}

.vh-25 {
  height: 25vh;
}

.mt-25vh {
  margin-top: 25vh;
}

.mb-25vh {
  margin-bottom: 25vh;
}

.instrucciones {
  font-size: 20px;
}

.disco {
  height: 40px;
  border-radius: 5px;
  transition: all 0.25s;
}

.flanker-input-container {
  padding-top: 50px;
  overflow: hidden;
  height: 50px;
}

.navbar-nav {
  margin-right: 0px;
  margin-left: auto;
  align-items: center;
}

#cruz-flanker {
  position: absolute;
  top: calc(50vh - 12px);
  left: calc(50vw - 12px);
  font-size: 42px;
}

#input-hidden {
  position: absolute;
  bottom: -1000px;
  left: 0px;
}

#navbarNav {
  margin-right: 0px;
  margin-left: auto;
  text-align: right;
}

@media (max-width: 567px) {
  h1 {
    font-size: 2em;
  }
  .navbar-nav {
    text-align: left;
    margin-left: 0px;
    margin-right: auto;
    align-items: flex-start;
  }
}

:root {
  --success: #28a745;
  --primary: #007bff;
  --danger: #dc3545;
}

.stroop-btn {
  min-height: 100px;
}

.stroop-container {
  height: 30vh;
}

.target-verde {
  color: #28a745;
  color: var(--success);
}

.target-rojo {
  color: #dc3545;
  color: var(--danger);
}

.target-azul {
  color: #007bff;
  color: var(--primary);
}

